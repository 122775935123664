import React from "react"
import Head from "../components/head"
import Header from "../layout/header"
import Footer from "../layout/footer"
import MobileMenu from "../components/mobileMenu"
import { scroller } from "react-scroll"
import ScrollMagic from "scrollmagic"
import CookieNotice from "../components/cookieNotice"
import gsap from "gsap"

import {
  appearAnimation,
  staggeredAppearAnimation,
} from "../includes/animations"

class MainLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageID: this.props.pageID,
      footerless: this.props.footerless,
      headerTheme: this.props.headerTheme,
      navType: this.props.navType,
      stickyHeader: false,
      seoData: this.props.seoData,
    }
  }

  removeHash() {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    )
  }

  componentDidMount() {
    // Scroll navigation

    if (this.props.page === "front") {
      if (window.location.hash) {
        const scrollDestination = window.location.hash.substr(1)

        this.removeHash()

        scroller.scrollTo(scrollDestination, {
          duration: 800,
          delay: 0,
          offset: -250,
          smooth: "easeInOutQuart",
        })
      }

      const controller = new ScrollMagic.Controller()

      Array.from(document.querySelectorAll(".appear")).forEach(el => {
        new ScrollMagic.Scene({
          triggerElement: el,
          triggerHook: 0.67,
        })
          .on("enter", e => {
            appearAnimation(el)
          })
          .addTo(controller)
      })

      new ScrollMagic.Scene({
        triggerElement: "#icons",
        triggerHook: 0.5,
      })
        .on("enter", e => {
          staggeredAppearAnimation(".icons__block", 0.085)
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: "#cta",
        triggerHook: 0.5,
      })
        .on("enter", e => {
          staggeredAppearAnimation(".cta__part", 0.085)
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: "#roadmap",
        triggerHook: 0.5,
      })
        .on("enter", e => {
          gsap.to(".points__separator", 0.5, {
            scaleX: 1,
            force3D: true,
            ease: "Power2.ease-out",
          })

          gsap.to(".point", 0.85, {
            delay: 0.4,
            opacity: 1,
            ease: "Power2.ease-out",
            stagger: function (index) {
              return index * 0.05
            },
          })
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: "#testimonials",
        triggerHook: 0.4,
      })
        .on("enter", e => {
          staggeredAppearAnimation(".testimonial", 0.25)
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: "#faq",
        triggerHook: 0.5,
      })
        .on("enter", e => {
          staggeredAppearAnimation(".accordion-pair-container", 0.085)
        })
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: "#contact",
        triggerHook: 0.4,
      })
        .on("enter", e => {
          staggeredAppearAnimation(".contact__part", 0.085)
        })
        .addTo(controller)
    }
  }

  render() {
    return (
      <>
        <Head seoData={this.state.seoData} />
        <main>
          <Header
            theme={this.state.headerTheme}
            navType={this.state.navType}
            ref={this.headerRef}
          />
          {this.props.children}
          {this.state.footerless === false && <Footer />}
          <MobileMenu navType={this.state.navType} />
          <CookieNotice />
        </main>
      </>
    )
  }
}

export default MainLayout
