import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Cookies from "universal-cookie"

const cookies = new Cookies()

class CookieNotice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.wordpressAcfOptions.options.cookie_notice,
      showNotice: false,
    }
  }

  setCookie() {
    cookies.set("CookieNotice", "seen", { path: "/" })
    this.setState({
      showNotice: false,
    })
  }

  checkCookie() {
    return cookies.get("CookieNotice") === "seen" ? true : false
  }

  componentDidMount() {
    if (this.checkCookie() === false) {
      this.setState({
        showNotice: true,
      })
    }
  }

  render() {
    const content = this.state.content

    return (
      <>
        {content.text &&
          content.accept_button_title &&
          this.state.showNotice === true && (
            <div id="cookie-notice">
              <div
                className="cookie-notice__text"
                dangerouslySetInnerHTML={{ __html: content.text }}
              />
              <div className="cookie-notice__accept">
                <button
                  className="btn btn--oval btn--oval--green"
                  onClick={() => this.setCookie()}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: content.accept_button_title,
                    }}
                  />
                </button>
              </div>
            </div>
          )}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            cookie_notice {
              text
              accept_button_title
            }
          }
        }
      }
    `}
    render={data => <CookieNotice data={data} {...props} />}
  />
)
