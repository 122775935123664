import gsap from "gsap"

const appearAnimation = target => {
  gsap.to(target, 0.6, {
    visibility: "visible",
    y: 0,
    opacity: 1,
    ease: "Power2.ease-out",
  })
}

const staggeredAppearAnimation = (target, stagger) => {
  gsap.to(target, 0.6, {
    visibility: "visible",
    y: 0,
    opacity: 1,
    ease: "Power2.ease-out",
    stagger: function (index) {
      return index * 0.1
    },
  })
}

export { appearAnimation, staggeredAppearAnimation }
