import React from "react"

class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
  }

  handleForm(e) {
    e.preventDefault()
  }

  render() {
    return (
      <div id="newsletter" ref={this.formRef}>
        <div
          id="mlb2-2136422"
          className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-2136422"
        >
          <div className="ml-form-align-center">
            <div className="ml-form-embedWrapper embedForm">
              <div className="ml-form-embedBody ml-form-embedBodyDefault row-form">
                <div className="ml-form-embedContent">
                  <h4>Palaikykime ryšį</h4>
                  <p>Prenumeruokite naujienlaiškį ir sužinokite apie naujus produktus</p>
                </div>
                <form
                  className="ml-block-form"
                  action="https://app.mailerlite.com/webforms/submit/p0d6x9"
                  data-code="p0d6x9"
                  method="post"
                  target="_blank"
                >
                  <div className="ml-form-formContent">
                    <div className="ml-form-fieldRow ml-last-item">
                      <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                        <label htmlFor="mailer-email">
                          <span>Jūsų el. paštas</span>
                          <input
                            id="mailer-email"
                            type="email"
                            className="form-control"
                            data-inputmask=""
                            name="fields[email]"
                            placeholder=""
                            autoComplete="email"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <input type="hidden" name="ml-submit" value="1" />
                  <div className="ml-form-embedSubmit">
                    <button type="submit" className="primary btn btn--oval btn--oval--empty">
                      Prenumeruoti
                    </button>
                    <button
                      disabled="disabled"
                      style={{ display: "none" }}
                      type="button"
                      className="loading"
                    >
                      {" "}
                      <div className="ml-form-embedSubmitLoad">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>{" "}
                    </button>
                  </div>
                </form>
              </div>
              <div
                className="ml-form-successBody row-success"
                style={{ display: "none" }}
              >
                <div className="ml-form-successContent">
                  <h4>Ačiū!</h4>
                  <p>Prenumerata sėkminga, kai bus naujienų mes jums parašysime.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Newsletter
