import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import OGImg from "../assets/img/fb-og.png"

class Head extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      seoData: this.props.seoData,
      faqData: this.props.data.wordpressPage.acf.faq.faq_,
    }
  }

  formFaqSchema(faq) {
    const faqSchema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        faq.map(pair => {
          return {
            "@type": "Question",
            name: `${pair.question}`,
            acceptedAnswer: {
              "@type": "Answer",
              text: `${pair.answer_short}`,
            },
          }
        }),
      ],
    }
    return faqSchema
  }

  render() {
    const faqSchema = this.formFaqSchema(this.state.faqData)

    const seo = this.state.seoData

    const ogTitle = seo.meta_title_fb ? seo.meta_title_fb : seo.meta_title
    const ogDesccription = seo.meta_description_fb
      ? seo.meta_description_fb
      : seo.meta_description
    const twTitle = seo.meta_title_tw ? seo.meta_title_tw : seo.meta_title
    const twDesccription = seo.meta_description_fb
      ? seo.meta_description_fb
      : seo.meta_description

    return (
      <Helmet>
        {seo.meta_title && <title>{seo.meta_title}</title>}
        {seo.meta_description && (
          <meta name="description" content={seo.meta_description} />
        )}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {twTitle && <meta property="twitter:title" content={twTitle} />}
        {ogDesccription && (
          <meta property="og:description" content={ogDesccription} />
        )}
        {twDesccription && (
          <meta property="twitter:description" content={twDesccription} />
        )}
        {OGImg && <meta property="og:image" content={OGImg} />}
        {OGImg && <meta property="twitter:image" content={OGImg} />}
        <html lang="en-US" />
        <link
          rel="icon"
          type="image"
          href={require("../../static/favicon.svg")}
        />
        <script src="/mailerlite.js" />
        {faqSchema && (
          <script type="application/ld+json">
            {JSON.stringify(faqSchema)}
          </script>
        )}
        )}
      </Helmet>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(wordpress_id: { eq: 2 }) {
          acf {
            faq {
              faq_ {
                question
                answer_short
              }
            }
          }
        }
      }
    `}
    render={data => <Head data={data} {...props} />}
  />
)
