import React from "react"
import { StaticQuery } from "gatsby"
import { Link as LinkScroll } from "react-scroll"
import { Link, graphql } from "gatsby"

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: this.props.data.allWordpressWpApiMenusMenusItems.edges[0].node,
      navType: this.props.navType,
      theme: this.props.theme,
      location: this.props.location,
    }
  }

  toggleMenu(e) {
    const mobileMenu = document.querySelector("#mobile-menu")
    const burger = document.querySelector(".js-menu-toggle")
    burger.classList.toggle("active")
    mobileMenu.classList.toggle("active")
  }

  render() {
    const menu =
      this.state.menu.name === "Header Menu EN" ? this.state.menu.items : false
    let menuCounter = 0

    let navID
    let isMobile

    if (this.state.location === "header") {
      navID = "header-nav"
      isMobile = false
    } else if (this.state.location === "mobile") {
      navID = "mobile-nav"
      isMobile = true
    }

    return (
      <nav
        id={navID}
        className={this.state.theme === "dark" ? "header-nav--dark" : ""}
      >
        <ul>
          {menu.map(item => {
            menuCounter++

            return (
              <li key={`menu-item-${menuCounter}`}>
                {this.state.navType === "scroll" && (
                  <LinkScroll
                    smooth={true}
                    duration={500}
                    offset={-100}
                    activeClass="active"
                    // # symbol is needed in WP, removing it here
                    to={item.url.substr(1)}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    onClick={
                      isMobile
                        ? e => {
                            this.toggleMenu()
                          }
                        : undefined
                    }
                  />
                )}
                {this.state.navType === "links" && (
                  <Link
                    to={`/${item.url}`}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                )}
              </li>
            )
          })}
          {this.state.navType === "scroll" && (
            <LinkScroll
              smooth={true}
              duration={500}
              offset={-100}
              activeClass="active"
              to="contact"
              className="btn btn--oval btn--oval--small btn--oval--green nav__cta"
              onClick={
                isMobile
                  ? e => {
                      this.toggleMenu()
                    }
                  : null
              }
            >
              <span>Susisiekite</span>
            </LinkScroll>
          )}
          {this.state.navType === "links" && (
            <Link
              to="/#contact"
              className="btn btn--oval btn--oval--small btn--oval--green nav__cta"
            >
              <span>Susisiekite</span>
            </Link>
          )}
        </ul>
      </nav>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              name
              items {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data => <Nav data={data} {...props} />}
  />
)
