import React from "react"
import { StaticQuery, graphql } from "gatsby"
import fb from "../assets/img/social/fb.svg"
import fbH from "../assets/img/social/fb-h.svg"
import ln from "../assets/img/social/ln.svg"
import lnH from "../assets/img/social/ln-h.svg"
import ins from "../assets/img/social/ins.svg"
import insH from "../assets/img/social/ins-h.svg"
import Newsletter from "../components/newsletter"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.wordpressAcfOptions.options.footer,
    }
  }

  groupIcons() {
    const icons = {
      fb: [fb, fbH],
      ln: [ln, lnH],
      ins: [ins, insH],
    }
    return icons
  }

  render() {
    const content = this.state.content
    const icons = this.groupIcons()

    const year = new Date().getFullYear()

    return (
      <footer>
        <div className="grid">
          <div className="footer__left">
            {/* <Link to="/" className="footer__logo">
              <img src={logo} alt="Inventi logo." />
            </Link> */}
            {content.social_title && (
              <h4
                className="white"
                dangerouslySetInnerHTML={{ __html: content.social_title }}
              />
            )}
            {content.social && (
              <div className="footer__social social">
                {content.social.map(soc => {
                  return (
                    <a
                      href={soc.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer__social__icon"
                      key={soc.network}
                    >
                      <img
                        className="icon__img"
                        src={icons[soc.network][0]}
                        alt="Social network icon."
                      />
                      <img
                        className="icon__img--hover"
                        src={icons[soc.network][1]}
                        alt="Social network icon."
                      />
                    </a>
                  )
                })}
              </div>
            )}
            <div className="footer__lower">
              <span className="footer__lower__copyright">{year} © Inventi</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/privacy-policy/"
              >
                Privatumo politika{" "}
              </a>
            </div>
          </div>
          <div className="footer__right">
            <Newsletter />
          </div>
        </div>
      </footer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            footer {
              social_title
              social {
                network
                link
              }
              privacy_policy_link {
                url
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
