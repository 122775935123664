import React from "react"
import Nav from "../components/nav"

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navType: this.props.navType,
    }
  }

  render() {
    return (
      <div id="mobile-menu">
        <div className="mobile-menu-container">
          <Nav location="mobile" navType={this.state.navType} />
        </div>
      </div>
    )
  }
}

export default MobileMenu
