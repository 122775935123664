import React from "react"
import { Link } from "gatsby"
import logo from "../assets/img/inventi.svg"

import Nav from "../components/nav"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: this.props.theme,
      navType: this.props.navType,
      sticky: this.props.sticky,
    }
  }

  toggleMenu(e) {
    const mobileMenu = document.querySelector("#mobile-menu")
    const burger = e.target.closest("button")
    burger.classList.toggle("active")
    mobileMenu.classList.toggle("active")
  }

  componentDidMount() {
    const toggleStickyHeader = () => {
      if (window.scrollY > 50) {
        this.setState({
          sticky: true,
        })
      } else {
        this.setState({
          sticky: false,
        })
      }
    }

    window.addEventListener("scroll", toggleStickyHeader)
    toggleStickyHeader()
  }

  render() {
    const themeClass = this.state.theme === "dark" ? "header--dark" : ""
    const stickyClass = this.state.sticky === true ? "header--sticky" : ""
    const headerClasses = `${themeClass} ${stickyClass}`

    return (
      <header className={headerClasses}>
        <div className="grid">
          <Link to="/" className="header__logo">
            <img
              // src={this.state.theme === "dark" ? logoBlack : logo}
              src={logo}
              alt="Inventi logo."
            />
          </Link>
          <Nav
            navType={this.state.navType}
            theme={this.state.theme}
            location="header"
          />
          <button
            className="btn btn--burger js-menu-toggle "
            onClick={e => this.toggleMenu(e)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </header>
    )
  }
}

export default Header
